<template>
  <div class="container">
    <div class="row">
      <div class="col-12 col-sm-3">
        <b-form-group label="Código">
          <b-input disabled v-model="obj.id" />
        </b-form-group>
      </div>
      <div class="col-12 col-sm-4">
        <b-form-group label="Vencimento">
          <b-input
            type="date"
            v-model="obj.dt_vencimento"
            :disabled="!!obj.dt_baixado"
          />
        </b-form-group>
      </div>
      <div class="col-12 col-sm-5 pt-4 mt-1">
        <b-card style="font-size: 10px" body-class="p-2 ">
          <b>Data Criação</b>
          <small>{{ obj.created_at | moment("DD/MM/YYYY HH:mm") }}</small>
          <b class="ml-2">Usuário</b> <small>{{ obj.usuario }}</small>
        </b-card>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-sm-8 pr-0">
        <b-form-group label="Cliente / Pagante">
          <select-paciente
            @paciente="selecionarPacienteObj"
            showFidelizacoes
            setNullOnEmpty
            :selecionado="entidade_id"
            :showBotaoAdd="false"
            :showBotaoEdit="false"
            :disabled="!!obj.dt_baixado"
          />
        </b-form-group>
        <b-form-group label="Observações" class="pr-4">
          <b-textarea
            v-model="obj.obs"
            style="height: 51px"
            :disabled="!!obj.dt_baixado"
          />
        </b-form-group>
      </div>
      <div class="col-12 col-sm-4 pl-0">
        <b-tabs content-class="card card-body">
          <b-tab>
            <template #title><b-icon-check-all /> Baixa</template>
            <contas-receber-fatura-baixa
              :showTitle="false"
              :faturaId="obj.id"
            />
          </b-tab>

          <b-tab>
            <template #title><b-icon-globe /> Integração</template>
            <hr class="mb-1" />
            <div class="text-danger mb-2 text-center" v-if="!obj.idExterno">
              <small><b-icon-x-circle-fill /> NÃO INTEGRADO</small>
              <b-btn
                size="sm"
                variant="success"
                block
                :disabled="enviandoERP"
                @click="enviarERP"
              >
                <span v-if="enviandoERP"><b-spinner /> aguarde...</span>
                <span v-if="!enviandoERP"><b-icon-hdd-network /> Enviar</span>
              </b-btn>
            </div>
            <div v-else class="text-success">
              <b-icon-check-circle /> Integração OK ({{ obj.idExterno }})
              <hr class="mt-1 mb-1" />
              <small>
                Integrado ás
                {{ obj.export_date | moment("DD/MM/YYYY HH:mm") }} por
                {{ obj.usuarioExport }}
              </small>
              <hr class="mt-1 mb-1" />

              <b-btn
                size="sm"
                variant="success"
                class="mt-3"
                block
                :disabled="enviandoERP"
                @click="enviarERP"
                v-if="!obj.dt_baixado"
              >
            
                <span v-if="enviandoERP"><b-spinner /> aguarde...</span>
                <span v-if="!enviandoERP">
                  <b-icon-hdd-network /> Re-Enviar
                </span>
              </b-btn>
            </div>
          </b-tab>
        </b-tabs>
      </div>
      <div class="col-6"></div>
      <div class="col-6"></div>
      <div class="col-12 col-sm-12"></div>
      <div class="col-12 col-sm-4"></div>
    </div>
    <b-card>
      <h3>
        <b-icon-list-ol /> Itens da Fatura
        <b-btn
          variant="info"
          v-if="!obj.dt_baixado"
          class="float-right"
          @click="openAddItem"
          size="lg"
          ><b-icon-plus-circle /> Adicionar</b-btn
        >
      </h3>
      <b-card-body body-class="p-2">
        <div class="row">
          <div class="col-12 mt-3 mb-3">
            <b-table
              :items="obj.itens"
              :show-empty="obj.itens.length <= 0"
              empty-text="Nenhum item adicionado ainda."
              :fields="[
                {
                  key: 'contas_receber_parcela_id',
                  label: 'Código',
                  class: 'text-center',
                },
                { key: 'parcela', label: 'Parcela', class: 'text-center' },
                {
                  key: 'dt_vencimento',
                  label: 'Vencimento',
                  class: 'text-center',
                },
                { key: 'entidadeNome', label: 'Cliente', class: 'text-left' },
                { key: 'vl_total', label: 'Valor', class: 'text-center' },
                { key: 'opc', label: '', class: 'text-center' },
              ]"
              style="font-size: 11px"
              hover
              small
              striped
            >
              <template #cell(entidadeNome)="row">
                <div>
                  {{ row.item.entidadeNome }}
                  <span v-if="row.item.entidadeNome2" class="pl-2">
                    <br />
                    <small>{{ row.item.entidadeNome2 }}</small>
                  </span>
                </div>
              </template>
              <template #cell(vl_total)="row">
                <div class="text-center">
                  {{ row.item.vl_total | currency }}
                </div>
              </template>
              <template #cell(parcela)="row">
                <div class="text-center">
                  {{ row.item.parcela }} / {{ row.item.parcelas }}
                </div>
              </template>
              <template #cell(dt_vencimento)="row">
                <div class="text-center">
                  {{ row.item.dt_vencimento | moment("DD/MM/YYYY") }}
                </div>
              </template>
              <template #cell(opc)="row">
                <div>
                  <b-btn
                    v-if="!obj.dt_baixado"
                    variant="non"
                    @click="exc(row.item)"
                    ><b-icon-trash
                  /></b-btn>
                </div>
              </template>
            </b-table>
          </div>
        </div>
      </b-card-body>
    </b-card>
    <div class="row">
      <div class="col-12 mt-3">
        <b-card>
          <h2><b-icon-cash /> Totais</h2>
          <b-card-body>
            <div class="row text-center">
              <div class="col-12 col-sm-3">
                <h4>Subtotal</h4>
                <money
                  class="form-control text-center"
                  v-model="obj.vl_subtotal"
                  v-bind="moneyMask"
                  disabled="true"
                ></money>
              </div>
              <div class="col-12 col-sm-3 text-danger">
                <h4>Desconto</h4>
                <money
                  class="form-control text-center"
                  v-model="obj.vl_desconto"
                  v-bind="moneyMask"
                  :disabled="!!obj.dt_baixado"
                ></money>
              </div>
              <div class="col-12 col-sm-3 text-success">
                <h4>Acrescimo</h4>
                <money
                  class="form-control text-center"
                  v-model="obj.vl_acrescimo"
                  v-bind="moneyMask"
                  :disabled="!!obj.dt_baixado"
                ></money>
              </div>
              <div class="col-12 col-sm-3">
                <h4>Total</h4>
                <money
                  class="form-control text-center"
                  v-model="obj.vl_total"
                  disabled="true"
                  v-bind="moneyMask"
                ></money>
              </div>
            </div>
          </b-card-body>
        </b-card>
      </div>
    </div>
    <b-modal
      id="modal-add-item-rec"
      hide-footer
      title="Adicionar Item"
      no-enforce-focus
      no-close-on-esc
      size="lg"
    >
      <contas-receber-buscar-simples
        @salvar="addItems"
        @salvarStay="addItemsStay"
        @close="closeAddItem"
        :excludeList="obj.itens"
        :entidade-id="obj.entidade_id"
      />
    </b-modal>
    <botoes-salvar-cancelar
      :saving="salvando"
      @salvar="salvar"
      @cancelar="$emit('close')"
      :saveDisabled="!!obj.dt_baixado"
    >
      <template v-slot:left>
        <b-btn variant="info" @click="$emit('close', obj.id)" v-if="obj.id"
          ><b-icon-printer /> Imprimir</b-btn
        >
      </template>
    </botoes-salvar-cancelar>
  </div>
</template>

<script>
import SelectPaciente from "../../../common/SelectPaciente.vue";
import BotoesSalvarCancelar from "../../../common/BotoesSalvarCancelar.vue";
import ContasReceberBuscarSimples from "../ContasReceberBuscarSimples.vue";
import ContasReceberLib from "../../../../libs/ContasReceberLib";
import ContasReceberFaturaBaixa from "./ContasReceberFaturaBaixa.vue";
// import moment from "moment";
export default {
  components: {
    SelectPaciente,
    BotoesSalvarCancelar,
    ContasReceberBuscarSimples,
    ContasReceberFaturaBaixa,
  },
  props: {
    id: Number,
  },
  mounted() {
    this.carregar();
  },
  data() {
    return {
      obj: {
        id: null,
        entidade_id: null,
        data: null,
        dt_vencimento: null,
        itens: [],
        vl_subtotal: 0,
        vl_desconto: 0,
        vl_acrescimo: 0,
        vl_total: 0,
        obs: "",
        itensExc: [],
      },
      entidade_id: null,
      salvando: false,
      enviandoERP: false,
    };
  },
  watch: {
    "obj.itens": function() {
      this.calcularTotal();
    },
    "obj.vl_desconto": function() {
      this.calcularTotal();
    },
    "obj.vl_acrescimo": function() {
      this.calcularTotal();
    },
    id: function() {
      this.carregar();
    },
  },
  computed: {},
  methods: {
    async carregar() {
      if (this.id) {
        let res = await ContasReceberLib.getFaturas({ id: this.id });
        console.log("res", res);
        if (res && res.success) {
          this.obj = res.data[0];
          this.entidade_id = this.obj.entidade_id;
          //this.$forceUpdate();
          // console.log("ob", this.obj);
        }
      }
    },
    async salvar() {
      let obj = this.obj;
      let err = [];
      if (
        !obj.entidade_id ||
        obj.entidade_id === 0 ||
        obj.entidade_id < 0 ||
        obj.entidade_id === ""
      ) {
        err.push("Informe um Devedor / Pagante");
      }
      if (!obj.dt_vencimento) {
        err.push("Informe um data de vencimento");
      }
      if (!obj.itens || obj.itens.length <= 0) {
        err.push("Informe ao menos 1 item para continuar");
      }

      if (err.length > 0) {
        this.$swal.fire({
          title: "Atenção",
          html: `Os campos abaixo precisam de atenção<hr/>${err.join("<hr/>")}`,
          icon: "error",
        });
      } else {
        this.salvando = true;
        try {
          console.log(obj);
          let res = await ContasReceberLib.storeFatura(obj);

          if (res && res.success) {
            this.$swal({
              title: "Concluído",
              html: "Fatura salva com sucesso!",
              icon: "success",
              showCancelButton: true,
              confirmButtonText: "Imprimir",
              cancelButtonText: "Apenas Fechar",
            }).then((r) => {
              this.$emit("close", r.isConfirmed ? res.data : null);
            });
          } else {
            this.$swal(
              "Erro",
              "Erro ao salvar" + (res && res.message ? res.message : ""),
              "error"
            );
          }
        } catch (err) {
          console.log("ERRO", err);
        }
        this.salvando = false;
      }
    },
    exc(item) {
      if (this.obj.id) {
        if (!this.obj.itensExc) {
          this.obj.itensExc = [];
        }
        this.obj.itensExc.push({ ...item });
      }
      this.obj.itens = this.obj.itens.filter((x) => x != item);
    },
    calcularTotal() {
      this.obj.vl_subtotal = this.obj.itens.reduce((ret, vl) => {
        ret += vl.vl_total;
        return ret;
      }, 0);

      this.obj.vl_total =
        (this.obj.vl_subtotal || 0) -
        (this.obj.vl_desconto || 0) +
        (this.obj.vl_acrescimo || 0);
    },
    selecionarPacienteObj(pac) {
      this.obj.entidade_id = null;
      if (pac && pac.id) {
        this.obj.entidade_id = pac;
      }
    },
    openAddItem() {
      this.$bvModal.show("modal-add-item-rec");
    },
    addItemsStay(items) {
      this.addItems(items, true);
    },
    addItems(items, stay = false) {
      items.forEach((it) => {
        if (
          !this.obj.itens.some(
            (x) => x.contas_receber_parcela_id == it.contas_receber_parcela_id
          )
        ) {
          this.obj.itens.push({
            contas_receber_id: it.id,
            contas_receber_parcela_id: it.contas_receber_parcela_id,
            parcela: it.parcela,
            dt_vencimento: it.dt_vencimento,
            entidade_id: it.entidade_id,
            venda_id: it.venda_id,
            valor: it.vl_aberto,
            vl_desconto: 0,
            vl_acrescimo: 0,
            vl_total: it.vl_aberto,
            parcelas: it.parcelas,
            entidadeNome: it.entidadeNome,
            entidadeNome2: it.entidadeNome2,
          });
        }
      });
      if (!stay) {
        this.closeAddItem();
      }
    },
    closeAddItem() {
      this.$bvModal.hide("modal-add-item-rec");
    },
    async enviarERP() {
      this.enviandoERP = true;

      setTimeout(() => {
        this.$nextTick(async () => {
          if (this.obj.id) {
            this.$swal({
              title: "Tem certeza?",
              text: "Deseja enviar a fatura par ao ERP?",
              showCancelButton: true,
              cancelButtonText: "Não",
              confirmButtonText: "Sim",
            }).then(async (r) => {
              if (r.isConfirmed) {
                let res = await ContasReceberLib.integracaoERP(this.obj.id);
                console.log("resposta", res);
                if (res && res.success) {
                  this.$swal(
                    "Concluído",
                    "Integração realizada com sucesso, codigo do contas a receber: " +
                      res.data,
                    "success"
                  );
                  this.carregar();
                } else {
                  this.$swal("Erro", "Erro ao gerar integração", "error");
                }
              }
              this.enviandoERP = false;
            });
          }
        });
      }, 200);
    },
  },
};
</script>

<style lang="scss" scoped></style>
