<template>
  <div class="container">
    <div class="row mt-3">
      <div class="col-12 text-left">
        <h3><i class="fa fa-file-alt" /> Faturas Contas a Receber</h3>
        <hr />
        <div class="row">
          <div class="col-12 col-sm-4 col-lg-4 col-xl-3 pr-1">
            <b-form-group label="Periodo Lançamento">
              <div class="row">
                <div class="col-6 pr-1">
                  <b-input
                    type="date"
                    v-model="filtros.data.dt_i"
                    style="padding:3px"
                  />
                </div>
                <div class="col-6 pl-0">
                  <b-input
                    type="date"
                    v-model="filtros.data.dt_f"
                    style="padding:3px"
                  />
                </div>
              </div>
            </b-form-group>
          </div>
          <div class="col-12 col-sm-2 col-lg-2 col-xl-3 pl-1 pr-1">
            <b-form-group label="Situação">
              <b-select
                :options="['Todos', 'Aberto', 'Baixado']"
                v-model="filtros.situacao"
              />
            </b-form-group>
          </div>
          <div class="col-12 col-sm-3 col-lg-3 col-xl-3 pl-1 pr-1">
            <b-form-group label="Pagante / Cliente / Empresa">
              <select-paciente
                :showBotaoAdd="false"
                :showBotaoEdit="false"
                @paciente="selecionarPacienteObj"
                showFidelizacoes
                setNullOnEmpty
                :selecionado="filtros.entidade_id"
              />
            </b-form-group>
          </div>
          <div class="col-12 col-sm-3 col-lg-3 col-xl-2 text-right pt-3">
            <b-btn
              style="margin-top:5px"
              variant="success"
              size="lg"
              @click="openBaixaModal(null)"
              ><b-icon-plus-circle /> Nova Fatura</b-btn
            >
          </div>
        </div>
        <hr />
        <div class="text-center p-5" v-if="loading">
          <b-spinner /><br />
          aguarde...
        </div>
        <b-table
          v-if="!loading"
          :items="lista"
          striped
          hover
          :fields="[
            {
              key: 'id',
              label: 'Código',
              sortable: true,
              class: 'text-center',
            },
            {
              key: 'dt_vencimento',
              label: 'Vencimento',
              sortable: true,
              class: 'text-center',
            },
            {
              key: 'sit',
              label: 'Situação',
              sortable: true,
              class: 'text-center',
            },
            {
              key: 'entidadeNome',
              label: 'Cliente / Pagante',
              sortable: true,
            },
            {
              key: 'vl_total',
              label: 'Valor',
              sortable: true,
              class: 'text-center',
            },
            {
              key: 'export_date',
              label: 'Integração',
              sortable: true,
              class: 'text-center',
            },
            {
              key: 'dt_baixado',
              label: 'Baixa',
              sortable: true,
              class: 'text-center',
            },
            {
              key: 'opts',
              label: '',
              class: 'text-center',
            },
          ]"
          empty-text="Nenhum registro encontrado!"
          :show-empty="!lista || lista.length <= 0"
        >
          <template #cell(sit)="row">
            <div class="text-center">
              {{ row.item.baixado == 1 ? "Baixado" : "Aberto" }}
            </div>
          </template>
          <template #cell(export_date)="row">
            <div class="text-center ">
              <i class="fa fa-ban" v-if="!row.item.export_date"></i>
              <div class="text-success">
                <i class="fa fa-check" v-if="row.item.export_date"></i>
                {{ row.item.export_date | moment("DD/MM/YYYY HH:mm") }}
              </div>
            </div>
          </template>
          <template #cell(dt_baixado)="row">
            <div class="text-center">
              <i class="fa fa-ban" v-if="!row.item.dt_baixado"></i>
              <div class="text-success">
                <i class="fa fa-check" v-if="row.item.dt_baixado"></i>
              {{ row.item.dt_baixado | moment("DD/MM/YYYY HH:mm") }}
              </div>
            </div>
          </template>
          <template #cell(dt_vencimento)="row">
            <div class="text-center">
              {{ row.item.dt_vencimento | moment("DD/MM/YYYY") }}
            </div>
          </template>
          <template #cell(vl_total)="row">
            <div class="text-center">
              {{ row.item.vl_total | currency }}
            </div>
          </template>
          <template #cell(opts)="row">
            <div class="text-center">
              <b-btn @click="imprimir(row.item.id)" variant="info" size="sm"
                ><b-icon-printer /> Imprimir</b-btn
              >
              <!-- <b-btn
                @click="baixa(row.item.id)"
                :variant="
                  !row.item.baixado || row.item.baixado == 0
                    ? 'success'
                    : 'danger'
                "
                size="sm"
              >
                <span v-if="!row.item.baixado || row.item.baixado == 0">
                  <b-icon-check-circle /> Baixar
                </span>
                <span v-if="row.item.baixado == 1">
                  <b-icon-x-circle /> Retirar Baixa
                </span>
              </b-btn> -->
              <b-btn
                @click="openBaixaModal(row.item.id)"
                variant="warning"
                size="sm"
                ><b-icon-pen /> Editar</b-btn
              >
              <b-btn
                @click="excluir(row.item.id)"
                variant="danger"
                :disabled="row.item.baixado == 1"
                size="sm"
                ><b-icon-trash /> Excluir</b-btn
              >
            </div>
          </template>
        </b-table>
      </div>
    </div>
    <b-modal
      id="modal-add-cr-baixa"
      hide-footer
      title="Adicionar/Editar Baixa"
      size="lg"
      no-close-on-backdrop
      no-close-on-esc
      no-enforce-focus
    >
      <ContasReceberFaturaForm @close="closeBaixaModal" :id="editID" />
    </b-modal>
    <b-modal id="modal-imprimir" hide-footer title="Impressão Fatura" size="lg">
      <contas-receber-fatura-impressao :fatura-id="editID" />
    </b-modal>
    <b-modal id="modal-baixa" hide-footer title="Baixar Fatura">
      <contas-receber-fatura-baixa
        :fatura-id="editID"
        @close="closeBaixaModal"
      />
    </b-modal>
  </div>
</template>

<script>
import moment from "moment";
import ContasReceberFaturaForm from "../../../components/Financeiro/ContasReceber/Fatura/ContasReceberFaturaForm.vue";
import ContasReceberLib from "../../../libs/ContasReceberLib";
import ContasReceberFaturaImpressao from "../../../components/Financeiro/ContasReceber/Fatura/ContasReceberFaturaImpressao.vue";
import SelectPaciente from "../../../components/common/SelectPaciente.vue";
import ContasReceberFaturaBaixa from "../../../components/Financeiro/ContasReceber/Fatura/ContasReceberFaturaBaixa.vue";
export default {
  components: {
    ContasReceberFaturaForm,
    ContasReceberFaturaImpressao,
    SelectPaciente,
    ContasReceberFaturaBaixa,
  },
  props: {},
  mounted() {
    this.carregar();
  },
  data() {
    return {
      filtros: {
        data: {
          dt_i: moment().format("YYYY-MM-01"),
          dt_f: moment(moment().format("YYYY-MM-01"))
            .add(1, "month")
            .add(-1, "day")
            .format("YYYY-MM-DD"),
        },
        situacao: "Todos",
        entidade_id: null,
      },
      sortBy: "id",
      sortDesc: false,
      lista: [],
      loading: false,
      editID: null,
    };
  },
  watch: {
    filtros: {
      deep: true,
      handler() {
        this.carregar();
      },
    },
  },
  computed: {},
  methods: {
    selecionarPacienteObj(obj) {
      //console.log(obj);
      this.filtros.entidade_id = obj.id;
    },
    async carregar() {
      this.loading = true;
      this.lista = [];
      try {
        let res = await ContasReceberLib.getFaturas({ ...this.filtros });
        console.log("ers", res);
        if (res && res.success) {
          this.lista = res.data;
        }
      } catch (err) {
        console.log("erro", err);
      }
      this.loading = false;
    },
    openBaixaModal(id) {
      this.editID = id;
      this.$bvModal.show("modal-add-cr-baixa");
    },
    async closeBaixaModal(imp) {
      this.$bvModal.hide("modal-add-cr-baixa");
      await this.carregar();
      if (imp) {
        this.imprimir(imp);
      }
    },
    imprimir(id) {
      this.editID = id;
      this.$nextTick();
      this.$bvModal.show("modal-imprimir");
    },
    baixa(id) {
      this.editID = id;
      this.$nextTick();
      this.$bvModal.show("modal-baixa");
    },
    excluir(id) {
      this.$swal({
        title: "Tem certeza?",
        text: "Tem certeza que deseja excluir esta fatura?",
        icon: "question",
        showCancelButton: true,
        cancelButtonText: "Não",
        confirmButtonText: "SIm. Excluir!",
      }).then(async (r) => {
        if (r.isConfirmed) {
          let res = await ContasReceberLib.excluirFatura({ id });

          console.log("resposta", res);
          if (res && res.success) {
            this.$swal("Excluido com sucesso!", "", "success");
            this.carregar();
          } else {
            this.$swal("Erro ao excluir!", "", "error");
          }
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
