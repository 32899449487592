<template>
  <div>
    <div class="card">
      <div class="card-body">
        <h5><b-icon-plus-circle /> Adicionar Item</h5>
        <b-form @submit.prevent="buscar">
          <div class="row">
            <div class="col-12 col-sm-5 pr-0">
              <b-form-group label="Vencimento">
                <div class="row">
                  <div class="col-6 pr-0">
                    <b-input type="date" v-model="filtros.periodo.dt_i" />
                  </div>
                  <div class="col-6 pl-1">
                    <b-input type="date" v-model="filtros.periodo.dt_f" />
                  </div>
                </div>
              </b-form-group>
            </div>
            <div class="col-12 col-sm-7 pl-1">
              <b-form-group
                label="Paciente / Fornecedor / Convenio / Laboratório"
              >
                <select-paciente
                  @paciente="selecionarPacienteFiltro"
                  showFidelizacoes
                  :showBotaoAdd="false"
                  :showBotaoEdit="false"
                  setNullOnEmpty
                  :selecionado="filtros.entidadeId"
                />
              </b-form-group>
            </div>

            <div class="col-12 col-sm-3 pr-1">
              <b-form-group label="Tipo">
                <b-select
                  :options="tipos"
                  v-model="filtros.tipo"
                  value-field="value"
                />
              </b-form-group>
            </div>
            <div class="col-12 col-sm-3 pl-0 pr-1">
              <b-form-group label="Cliente">
                <b-input v-model="filtros.cliente" />
              </b-form-group>
            </div>
            <div class="col-12 col-sm-3 pl-0 pr-1">
              <b-form-group label="Paciente">
                <b-input v-model="filtros.paciente" />
              </b-form-group>
            </div>
            <div class="col-12 col-sm-3 text-right pt-4">
              <b-btn
                variant="primary"
                size="lg"
                @click="buscar"
                :disabled="loading"
                type="submit"
                ><b-icon-search /> Buscar</b-btn
              >
            </div>
          </div>
        </b-form>
        <div class="text-center p-5" v-if="loading">
          <b-spinner /><br />
          aguarde...
        </div>
        <diV
          v-if="
            resultados &&
              resultados.length > 0 &&
              selecionados &&
              selecionados.length > 0
          "
          class="d-flex justify-content-end"
        >
          <b-btn variant="info" @click="salvar(true)"
            ><b-icon-plus-circle /> Adicionar Selecionados</b-btn
          >
          <b-btn variant="secondary" @click="cancelar"
            ><b-icon-x /> Cancelar</b-btn
          >
        </diV>

        <b-table
          v-if="!loading"
          :items="resultados"
          small
          striped
          hover
          ref="selectableTable"
          id="selectableTable"
          selectable
          @row-selected="onRowSelected"
          :show-empty="!resultados || resultados.length <= 0"
          empty-text="Nenhum registro encontrado com os filtros informados"
          :fields="[
            { key: 'sel', label: '', class: 'text-center' },
            {
              key: 'id',
              label: 'Código',
              class: 'text-center',
              sortable: true,
            },
            {
              key: 'parcela',
              label: 'Parcela',
              class: 'text-center',
              sortable: true,
            },
            {
              key: 'tipo',
              label: 'Tipo',
              class: 'text-center',
              sortable: true,
            },
            {
              key: 'dt_vencimento',
              label: 'Vencimento',
              class: 'text-center',
              sortable: true,
            },
             {
              key: 'entidadeNome',
              label: 'Empresa',
              class: 'text-left',
              sortable: true,
            },
            {
              key: 'nome',
              label: 'Cliente',
              class: 'text-left',
              sortable: true,
            },
            {
              key: 'vl_aberto',
              label: 'Valor',
              class: 'text-center',
              sortable: true,
            },
          ]"
        >
          <template #cell(nome)="row">
            <div>
              <span v-if="row.item.entidadeNome2" class="">
                {{ row.item.entidadeNome2 }}
                <small v-if="row.item.entidadeNome3">
                  <br />
                  <i class="fab fa-accessible-icon"></i>
                  {{ row.item.entidadeNome3 }}
                </small>
              </span>
              <span v-else>
                {{ row.item.entidadeNome }}
              </span>
            </div>
          </template>
          <template #head(sel)>
            <b-checkbox :value="1" v-model="checkall" />
          </template>
          <template #cell(sel)="row">
            <b-checkbox v-model="selecionados" :value="row.item" />
          </template>
          <template #cell(vl_aberto)="row">
            <div class="text-center">
              {{ row.item.vl_aberto | currency }}
            </div>
          </template>
          <template #cell(parcela)="row">
            <div class="text-center">
              {{ row.item.parcela }} / {{ row.item.parcelas }}
            </div>
          </template>
          <template #cell(dt_vencimento)="row">
            <div class="text-center">
              {{ row.item.dt_vencimento | moment("DD/MM/YYYY") }}
            </div>
          </template>
          <template #cell(id)="row">
            {{ row.item.id }}<br />
            <small>Venda: {{ row.item.vendaID }}</small>
          </template>
        </b-table>
      </div>
    </div>
    <BotoesSalvarCancelar @salvar="salvar" @cancelar="cancelar" />
  </div>
</template>

<script>
import SelectPaciente from "../../common/SelectPaciente.vue";
import moment from "moment";
import ContasReceberLib from "../../../libs/ContasReceberLib";
import BotoesSalvarCancelar from "../../common/BotoesSalvarCancelar.vue";
export default {
  components: { SelectPaciente, BotoesSalvarCancelar },
  props: {
    excludeList: Array,
    entidadeId: Object,
  },
  mounted() {},
  data() {
    return {
      tipos: [
        { value: "-1", text: "Todos" },
        { value: "CONVENIO", text: "Convenio" },
        { value: "DEF", text: "Desconto em Folha" },
        { value: "CREDIARIO", text: "Crediário" },
      ],
      filtros: {
        periodo: {
          dt_i: moment().format("YYYY-MM-DD"),
          dt_f: moment().format("YYYY-MM-DD"),
        },
        entidadeId: this.entidadeId,
        tipo: "-1",
        cliente: "",
        paciente: "",
      },
      resultados: [],
      loading: false,
      selecionados: [],
      checkall: 0,
    };
  },
  watch: {
    checkall: function() {
      if (this.checkall == 1) {
        this.selecionados = this.resultados;
      } else {
        this.selecionados = [];
      }
    },
  },
  computed: {},
  methods: {
    onRowSelected(row) {
      if (row && row.length > 0) {
        let id = row[0].contas_receber_parcela_id;
        if (this.selecionados.some((x) => x.contas_receber_parcela_id == id)) {
          this.selecionados = this.selecionados.filter(
            (x) => x.contas_receber_parcela_id != id
          );
        } else {
          this.selecionados.push(
            this.resultados.find((x) => x.contas_receber_parcela_id == id)
          );
        }
      }

      this.$refs.selectableTable.clearSelected();
    },
    selecionarPacienteFiltro(pac) {
      this.filtros.entidadeId = null;
      if (pac && pac.id) {
        this.filtros.entidadeId = pac.id;
      }
    },
    async buscar() {
      if (!this.filtros.entidadeId && this.filtros.tipo == "-1") {
        this.$swal("Atenção", "Selecione um tipo ou um Paciente / Fornecedor / Convenio!", "error");
        return;
      }
      this.loading = true;
      this.resultados = [];
      try {
        let objBnusca = {
          periodo: this.filtros.periodo,
          entidade_id:
            this.filtros.entidadeId &&
            typeof this.filtros.entidadeId === "object"
              ? this.filtros.entidadeId.id
              : this.filtros.entidadeId,
          situacao: "ABERTO",
          tipo: this.filtros.tipo,
          semFatura: true,
          paciente: this.filtros.paciente,
          cliente: this.filtros.cliente,
        };
        // console.log(objBnusca);
        let res = await ContasReceberLib.getParcelas(objBnusca);

        // console.log("result", res, this.excludeList);
        if (res && res.success) {
          if (this.excludeList && this.excludeList.length > 0) {
            res.data = res.data.filter(
              (x) =>
                !this.excludeList.some(
                  (a) =>
                    a.contas_receber_parcela_id == x.contas_receber_parcela_id
                )
            );
          }
          this.resultados = res.data;
          this.resultados = this.resultados.map((it) => ({
            ...it,
            nome: it.entidadeNome3 || it.entidadeNome2 || it.entidadeNome,
          }));
          if (this.filtros.cliente && this.filtros.cliente != "") {
            this.resultados = this.resultados.filter(
              (x) =>
                (x.entidadeNome2 &&
                  x.entidadeNome2
                    .toLowerCase()
                    .includes(this.filtros.cliente.toLowerCase().trim())) ||
                (x.entidadeNome &&
                  x.entidadeNome
                    .toLowerCase()
                    .includes(this.filtros.cliente.toLowerCase().trim()))
            );
          }
          if (this.filtros.paciente && this.filtros.paciente != "") {
            this.resultados = this.resultados.filter(
              (x) =>
                x.entidadeNome3 &&
                x.entidadeNome3
                  .toLowerCase()
                  .includes(this.filtros.paciente.toLowerCase().trim())
            );
          }
        }
      } catch (err) {
        console.log("erro ", err);
      }
      this.loading = false;
    },
    cancelar() {
      this.$emit("close");
    },
    async salvar(stay = false) {
      if (!this.selecionados || this.selecionados.length <= 0) {
        this.$swal(
          "Atenção",
          "Selecione ao menos 1 item para adicionar!",
          "error"
        );
        return;
      }
      if (!stay) {
        this.$emit("salvar", this.selecionados);
      } else {
        await this.$emit("salvarStay", this.selecionados);
        await this.$nextTick();
        setTimeout(() => {
          this.selecionados = [];
          this.buscar();
        }, 220);
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
