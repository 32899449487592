<template>
  <div class="text-center">
    <b-spinner v-if="loading" />
    <div class="p-0" v-if="fatura">
      <h2 v-if="showTitle">Fatura #{{ fatura.id }}</h2>
      <hr class="pt-1 pb-1" v-if="showTitle" />
      <div v-if="!fatura.baixado || fatura.baixado == 0" class="text-danger">
        <b-icon-x-circle /> A fatura está <b>ABERTA</b>.
        <br />
        <!-- <b-btn variant="success" @click="realizarBaixa" class="mt-2"
          ><b-icon-check /> BAIXAR FATURA</b-btn
        > -->
      </div>
      <div v-if="fatura.baixado && fatura.baixado == 1" class="text-success">
        <b><b-icon-check /> A fatura está Baixada!</b>
        <br />
        {{ fatura.dt_baixado | moment("DD/MM/YYYY HH:mm") }} por
        {{ fatura.userBaixou }}
        <hr />
        <b-btn variant="danger" class="mt-2" @click="realizarBaixa"
          ><b-icon-check /> RETIRAR BAIXA</b-btn
        >
      </div>
    </div>
    <div v-else class="text-center">
      Erro ao carregar os registros.
    </div>
  </div>
</template>

<script>
import ContasReceberLib from "../../../../libs/ContasReceberLib";
export default {
  components: {},
  props: {
    faturaId: Number,
    showTitle: {
      type: Boolean,
      default: true
    }
  },
  mounted() {
    this.carregar();
  },
  data() {
    return {
      fatura: null,
      loading: false
    };
  },
  watch: {
    faturaId: function() {
      this.carregar();
    }
  },
  computed: {},
  methods: {
    async carregar() {
      this.loading = true;
      if (this.faturaId) {
        let res = await ContasReceberLib.getFaturas({ id: this.faturaId });
        console.log("carrega bx", res);
        if (res && res.success) {
          this.fatura = res.data[0];
        }
      }
      this.loading = false;
    },
    async realizarBaixa() {
      if (this.fatura) {
        this.$swal({
          title: "Tem certeza?",
          text: `Deseja ${
            this.fatura.baixado == 1 ? "retirar a baixa" : "baixar"
          } a fatura?`,
          showCancelButton: true,
          cancelButtonText: "Não",
          confirmButtonText: "Sim"
        }).then(async r => {
          if (r.isConfirmed) {
            let res = await ContasReceberLib.baixaFatura(this.fatura.id);
            console.log("resposta", res);
            if (res && res.success) {
              this.$swal(
                "Concluído",
                this.fatura.baixado == 1
                  ? "Baixa retirada com sucesso."
                  : "Baixa realizada com sucesso.",
                "success"
              );
              this.carregar();
              this.$emit("close");
            } else {
              this.$swal("Erro", "Erro ao gerar integração", "error");
            }
          }
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
</style>