import axios from '../utils/axios.js';


const getParcelas = async (data) => {
    try {
        return await (await (axios.post("/contas_receber/getParcelas", data))).data;
    } catch (err) {
        console.log("erro get parcelas", err);
        return null
    }
    
}

const storeFatura = async (data) => {
    try {
        return await (await (axios.post("/contas_receber/faturas/store", data))).data;
    } catch (err) {
        console.log("erro store faturas", err);
        return null
    }

}
const getFaturas = async (data) => {
    try {
        return await (await (axios.post("/contas_receber/faturas/get", data))).data;
    } catch (err) {
        console.log("erro store faturas", err);
        return null
    }

}
const excluirFatura = async (data) => {
    try {
        return await (await (axios.post("/contas_receber/faturas/delete", data))).data;
    } catch (err) {
        console.log("erro store faturas", err);
        return null
    }

}

const integracaoERP  = async (id) => {
    try {
        return await (await (axios.post("/contas_receber/faturas/integrarERP", { id }))).data;
    } catch (err) {
        console.log("erro store faturas", err);
        return null
    }

}



const baixaFatura = async (id) => {
    try {
        return await (await (axios.post("/contas_receber/faturas/baixaFatura", { id }))).data;
    } catch (err) {
        console.log("erro store faturas", err);
        return null
    }

}







export default {
    getParcelas,
    storeFatura,
    getFaturas,
    excluirFatura,
    integracaoERP,
    baixaFatura
}