<template>
  <div>
    <div v-if="!printNow" class="text-center p-4">
      <b-spinner /> <br />
      aguarde...
    </div>
    <b-btn variant="info" @click="print"
      ><i class="fa fa-print mr-1" /> Imprimir</b-btn
    >
    <div id="printDiv">
      <print-component
        :filename="`Fatura ${fatura.entidadeNome}`"
        v-if="fatura"
        :printNow="true"
        @done="$emit('done')"
        :show="true"
        :titulo="`Fatura ${fatura.entidadeNome} - ${fatura.data}`"
      >
        <section class="pdf-item">
          <table class="table table-sm mt-4 ">
            <tbody>
              <tr>
                <th class="bg-light" style="width: 150px">Código Controle</th>
                <th>
                  {{ fatura.id }}
                </th>
                <th class="bg-light" style="width: 100px">Vencimento</th>
                <td class="text-center">{{ fatura.dt_vencimento | moment("DD/MM/YYYY") }}</td>
              </tr>
              <tr>
                <th class="bg-light">Pagante</th>
                <td colspan="2">{{ fatura.entidadeNome }}</td>

                <td
                  :class="{
                    'text-right':
                      fatura.vl_desconto > 0 || fatura.vl_acrescimo > 0,
                    'text-center': !fatura.vl_desconto && !fatura.vl_acrescimo,
                  }"
                  rowspan="2"
                  style="border-left:1px solid #ccc"
                >
                  <div class="text-center bg-light pb-1 pt-1">
                    <b class="text-center">Valor</b>
                  </div>
                  <hr class="mt-0 mb-1" />
                  <span
                    v-if="fatura.vl_desconto > 0 || fatura.vl_acrescimo > 0"
                  >
                    <b>Subtotal: </b> {{ fatura.vl_subtotal | currency }}
                    <span
                      v-if="fatura.vl_desconto > 0"
                      class="text-left text-danger"
                    >
                      <br />
                      <b>Desconto: </b> {{ fatura.vl_desconto | currency }}
                    </span>
                    <span v-if="fatura.vl_acrescimo > 0" class="text-success">
                      <br />
                      <b>Acrescimo: </b> {{ fatura.vl_acrescimo | currency }}
                    </span>
                    <br />
                    <hr class="mt-1 mb-1" />
                    <b>Total: </b>
                  </span>
                  {{ fatura.vl_total | currency }}
                </td>
              </tr>
              <tr>
                <th class="bg-light">Observações</th>
                <td colspan="3">{{ fatura.obs }}</td>
              </tr>
            </tbody>
          </table>
          <table class="table table-sm table-striped" style="font-size:10px;">
            <thead>
              <tr>
                <th class="text-center">Código</th>
                <th class="text-center">Parcela</th>
                <th>Cliente</th>
                <th class="text-center">Valor</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="it in fatura.itens"
                :key="`tr_lni_item_${it.id}_${Math.random(232323232)}`"
              >
                <td class="text-center">{{ it.contas_receber_id }}</td>
                <td class="text-center">
                  {{ it.parcela }} / {{ it.parcelas }}
                </td>
                <td>{{ it.entidadeNome2 }}</td>
                <td class="text-center">{{ it.vl_total | currency }}</td>
              </tr>
            </tbody>
          </table>
        </section>
      </print-component>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import ContasReceberLib from "../../../../libs/ContasReceberLib";
import PrintComponent from "../../../Print/PrintComponent.vue";
import PrinterWindowHelper from "../../../../helpers/PrinterWindowHelper";

export default {
  components: { PrintComponent },
  props: {
    faturaId: Number,
  },
  mounted() {
    this.carregar();
  },
  data() {
    return {
      printNow: false,
      fatura: null,
    };
  },
  watch: {},
  computed: {},
  methods: {
    async carregar() {
      if (this.faturaId) {
        let res = await ContasReceberLib.getFaturas({ id: this.faturaId });
        if (res && res.success) {
          this.fatura = res.data[0];
          this.fatura.data = moment(this.fatura).format("DD/MM/YYYY");
          this.printNow = true;
          console.log("item", this.fatura);
        }
      }
    },
    print() {
      PrinterWindowHelper(
        document.getElementById("printDiv").innerHTML,
        5,
        false
      );
    },
  },
};
</script>

<style lang="scss" scoped></style>
